import React, { useContext, useState, useEffect, useRef } from "react";
import { API } from "../../api-config";
import SectionsHead from "../Home/Heading";
import "../../style/partials/components/_reviews.scss";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { useAPI } from "../../api/apicounselllor";
import profile from "../../images/test.png";
import { Rating } from "react-simple-star-rating";
import Multiselect from "multiselect-react-dropdown";
import SearchFilter from "./SearchFilter";
import "../../style/partials/components/_Products.scss";
import ai from "../../images/a2z-bg.jpg";
import Page from "./Pages";
import UserContext from "../../contexts/UserContext";
import Button from "react-bootstrap/Button";
let min = null;
let max = null;
let i = 0;
let offset = 1;
let feedOffset = 0;
let wait = false;
const CounsellorResult = () => {
const navigate = useNavigate();
const [len, setLen] = useState(0);
const [pagesCount, setPagesCount] = useState(0);
const [cats, setCats] = useState([]);
const [results, setResult] = useState();
const searchRef = useRef();
const emp = useLocation();
const [filters, setFilters] = useState([]);
const [offset, setOffset] = useState(0);
const [keyword, setKeyword] = useState('');
const [key, setKey] = useState('');
const [entries, setEntries] = useState(20);
const [pages, setPages] = useState(0);
  useEffect(() => {
    const params = new URLSearchParams(emp.search);
    
    const pValue = params.get('p') ? Number(params.get('p')) : 1;
    const filterData = [
      params.get('cat') ? params.get('cat').split(',') : [],
      params.get('subcat') ? params.get('subcat').split(',') : [],
      params.get('skills') ? params.get('skills').split(',') : [],
      params.get('review') ? [params.get('review')] : [],
      params.get('q') ? [params.get('q')] : [],
      [pValue]
    ];

    setFilters(filterData); 
    searchCounsellor(filterData);
  }, [emp.search,keyword,offset]);


  // //Pagination
  useEffect(() => {
    setPagesCount(Math.floor(len / entries));
  }, [len]);

  useEffect(() => {

  }, [pages]);

  useEffect(() => {

  }, [cats]);

/*
  useEffect(() => {
    console.log("offest")
    searchCounsellor(filters);
  }, [offset]);

*/
  const pageNum = (num) => {
    const params = new URLSearchParams(emp.search);

    const filterData = [
      params.get('cat') ? params.get('cat').split(',') : [],
      params.get('subcat') ? params.get('subcat').split(',') : [],
      params.get('skills') ? params.get('skills').split(',') : [],
      params.get('review') ? [params.get('review')] : [],
      params.get('q') ? [params.get('q')] : [],
      [num + 1]
    ];

    console.log("filterdata12",filterData)
    setFilters(filterData); // Set initial filters from URL
    searchCounsellor(filterData); 
  };

  const accomodatePage = (clickedPage) => {
    if (clickedPage <= 1) {
      return clickedPage + 1;
    }
    if (clickedPage >= pagesCount) {
      return clickedPage - 1;
    }
    return clickedPage;
  };

  const searchCounsellor = async (selectedFilters) => {
    try {
      let newKeyword ='';

      //setFilters((prevState) => selectedFilters);
      const params = new URLSearchParams(emp.search);

      // Construct additional query parameters from selectedFilters
      if (selectedFilters[0]?.length > 0) {
        params.set('cat', selectedFilters[0].join(',')); // category
      }else{
        params.delete('cat');
      }
      if (selectedFilters[1]?.length > 0) {
        params.set('subcat', selectedFilters[1].join(',')); // subcategory
      }else{
        params.delete('subcat');
      }
      if (selectedFilters[2]?.length > 0) {
        params.set('skills', selectedFilters[2].join(',')); // skills
      }else{
        params.delete('skills');
      }
      if (selectedFilters[3]?.length > 0) {
        params.set('review', selectedFilters[3].join(',')); // reviews
      }else{
        params.delete('review');
      }
      
      if (selectedFilters[4] && selectedFilters[4][0] !== undefined && selectedFilters[4][0] !== '') {
        newKeyword  = selectedFilters[4][0];
        setKeyword(newKeyword);
        params.set('q', newKeyword);
        //selectedFilters.splice(4, 1);
      }
      if (selectedFilters[5] && selectedFilters[5][0] !== undefined && selectedFilters[5][0] !== '') {
        let page  = selectedFilters[5][0];
        params.set('p', page);
        setOffset(page-1);
        //selectedFilters.splice(5, 1);
      }else{
        params.set('p', 1);
        setOffset(0);
      }

      navigate({ search: params.toString() }, { replace: true });
      
      if (!( selectedFilters[0]?.length > 0 || selectedFilters[1]?.length > 0 ||  selectedFilters[2]?.length > 0 || selectedFilters[3]?.length > 0 )) {
        selectedFilters = [];
        const response = await axios.post(`${API}/api/session/BySearch`, {
          keyword: `${keyword == '' ? newKeyword :keyword}`,
          filtered: selectedFilters,
          offset: offset,
          once: false,
        });
        setResult(response.data.SessiondataFilter);
        setLen((prevState) => response.data.counts);
        setCats((prevState) => response.data.categFilt);
      } else {
        console.log(selectedFilters);
        const response = await axios.post(`${API}/api/session/BySearch`, {
          keyword: `${keyword == '' ? newKeyword :keyword}`,
          filtered: selectedFilters,
          offset: offset,
          once: false,
        });
        console.log("THIS IS RESPONSE 12",response.data);
        setResult(response.data.SessiondataFilter);
        setLen((prevState) => response.data.counts);
        setCats((prevState) => response.data.categFilt);
      }
    } catch (error) {
      console.log("ERROR", error);
    }
  };

  const handleSearch = async (event) => {
    setKeyword(searchRef.current.value.trim().split(" "));
    const params = new URLSearchParams(emp.search);
    params.set('q', searchRef.current.value.trim().split(" "));
    params.set('p', 1);
    console.log(params.toString());
    navigate({ search: params.toString() }, { replace: true });
  };

 
  return (
    <>
      <div className="container mt-5 mb-5">
        <SectionsHead heading="Sessions You Might Like" />
        <div className="row pt-3 pb-5 text-center">
          <div className="col-sm-3"></div>
          <div className="col-sm-6">
            <input
              className="p-2 form-control"
              placeholder="Search "
              id="search"
              defaultValue={keyword}
              ref={searchRef}
            />
            </div>
            <div className="col-sm-3 text-left pl-0">
              <Button
                className="drop-btn-costmize m-1 mt-0"
                onClick={(event) => {
                  handleSearch(event);
                }}
              >
                Search
              </Button>
          </div>
        </div>

        <div className="row pt-3 pb-3">
          <div className="col-md-3">
            {Array.isArray(cats) && cats.length && (
              <SearchFilter
                handleSearchCounsellor={searchCounsellor}
                categories={cats}
                filters={filters}
              />
            )}
          </div>
          <div className="col-md-9">
          {results &&
            (results.length > 0 ? (
              <div className="row">
                {results.map((items, index) => (
                    <div key={`${items._id}${index}`} className="my-custom-styles col-lg-4 col-md-6 col-sm-6">
                      <div className="session-card-div shadow-sm">
                        <a onClick={() => {
                            navigate("/session-details", {
                              state: {
                                id: items._id,
                                bid: items.sessionNumber,
                                cost: items.sessionCost,
                                defaultCurrency: items.defaultCurrency
                                  ? items.defaultCurrency
                                  : "INR",
                              },
                            });
                          }} href="" className="hero-image-container">
                          <img
                            className="hero-image"
                            src={items.image ? items.image : ai}
                            alt="{items.sessiontitle}"
                            loading="lazy"
                          />
                        </a>
                        <main className="main-content">
                          <div className="d-block">
                            <p className="fw-bold tittel">
                              {items.sessiontitle}
                            </p>
                            {/* <label>Author : </label>
                            <span className="ms-auto">{`${items?.counsellorId[0]?.fName}`} {`${items?.counsellorId[0]?.lName}`}</span> */}
                          </div>
                          <div className="flex-row skills">
                            <div className="time-left flex-wrap">
                              <label className="fw-bolder">Skills :</label>
                              {items?.skills?.slice(0, 3).map((ele, index2) => (
                                <p key={`${ele.skill}${index2}`}>
                                {ele.skill}
                                {index2 < items.skills.slice(0, 3).length - 1 ? ',  ' : ''}
                                </p>
                              ))}
                            </div>
                          </div>
                          <div className="description">
                            <label>Description : </label>
                            <p className="textdesc">{items?.sessionDes}</p>
                          </div>
                          <div className="flex">
                            <div className="coin-base justify-content-between">
                              <label>Cost : </label>
                              <span>
                                {items.sessionCost +
                                  " " +
                                  items.defaultCurrency}
                              </span>
                              <div className="book-session">
                                <button
                                  className="booking"
                                  onClick={() => {
                                    navigate("/session-details", {
                                      state: {
                                        id: items._id,
                                        bid: items.sessionNumber,
                                        cost: items.sessionCost,
                                        defaultCurrency: items.defaultCurrency
                                          ? items.defaultCurrency
                                          : "INR",
                                      },
                                    });
                                  }}
                                >
                                  Book Session
                                </button>
                              </div>
                            </div>
                          </div>
                        </main>
                      </div>
                    </div>
                  ))}
                  </div>
            ) : (
              <h6>No sessions available in this domain</h6>
            ))}
            {pagesCount > 1 && (
              <div className="a2zpagination">
                {offset > 0 && (
                  <button 
                    data-key={offset}
                    className="index-btn mb-2 mt-2"
                    onClick={() => {
                      pageNum(offset - 1 >= 0 ? offset - 1 :1);
                    }}
                  >
                    {<i className="fa-solid fa-chevron-left"></i>}
                  </button>
                )}
                {(() => {
                  const buttons = [];

                  if (pagesCount >= 3) {
                    const currentPageNum = accomodatePage(offset);
                    for (let i = -1; i < 2; i++) {
                      const pageNumToShow = currentPageNum + i;
                      buttons.push(
                        <button
                          key={pageNumToShow}
                          className={`index-btn mb-2 mt-2 ${pageNumToShow === offset ? 'active' : ''}`}
                          onClick={() => {
                            pageNum(pageNumToShow);
                          }}
                        >
                          {currentPageNum + i + 1}
                        </button>
                      );
                    }
                  } else {
                    for (let i = 0; i < pagesCount; i++) {
                      const pageNumToShow = 1 + i;
                      buttons.push(
                        <button
                          key={pageNumToShow}
                          className={`index-btn mb-2 mt-2 ${pageNumToShow === offset ? 'active' : ''}`}
                          onClick={() => {
                            pageNum(1 + i);
                          }}
                        >
                          {1 + i}
                        </button>
                      );
                    }
                  }
                  return buttons;
                })()}
                {offset != pagesCount && (
                  <button
                    className="index-btn mb-2 mt-2"
                    onClick={() => {
                      pageNum(offset + 1 <= pagesCount ? offset + 1 : pagesCount);
                    }}
                  >
                    {<i className="fa-solid fa-chevron-right"></i>}
                  </button>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default React.memo(CounsellorResult);

