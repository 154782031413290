import React, { useContext, Suspense } from "react";
import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import Home from "./components/Home/Home";
import Studenthome from "./components/Student/Home/Home";
import CounsellorHome from "./components/Counsellor/Home/Home";
import SelectCounselling from "./components/Common/SelectCounselling";
import Sessiondetails from "./components/Student/Studentsession/Sessiondetails";
import Conformation from "./components/Counsellor/conformationsession/conformationsession";
import Login from "./components/form/Login";
import Register from "./components/form/Register";
import Eventcreate from "./components/Counsellor/EventCreate/Eventcreate";
import PageNotfound from "./components/Pagenotfound/Pagenotfound";
import CounsellorProfile from "./components/Counsellor/CounsellorProfile/CounsellorProfile";
import UpdateProfile from "./components/Counsellor/CounsellorProfile/Update-Profile";
import StudentProfile from "./components/Student/StudentProfile/StudentProfile";
import EditProfile from "./components/Student/StudentProfile/EditProfile";
import StudentWallet from "./components/Student/Wallet/Studentwallet";
import UpcomingStudentSession from "./components/Student/UpcomingSession/UpcomingStudentSession";
import CounsellorUpcomingSession from "./components/Counsellor/upcomingSession/UpcomingSession";
import CompletedSession from "./components/Student/CompletedSession/completedsessions";
import CounsellorResult from "./components/SeacrhResult/CounsellorResult";
import HowItWorks from "./components/HowItWorks/HowItWorks";
import OurExperts from "./components/OurExpert/Experts";
import WalletPayment from "./components/Student/SessionPayment/BidPayment";
import Aboutus from "./components/Aboutus/Aboutus";
import Privacypolicy from "./components/PrivacyPolicy/Privacypolicy";
import Cookies from "./components/Cookies/Cookies";
import TAC from "./components/TermsAndServices/Termsandservices";
import Faqs from "./components/AllFAQ/AllFaqs";
import Reviews from "./components/Reviews/Reviews";
import AcademicSuccess from "./components/Categories/AcademicSuccess";
import AIServices from "./components/Categories/AIServices";
import Business from "./components/Categories/Business";
import Data from "./components/Categories/Data";
import DigitalMarketing from "./components/Categories/DigitalMarketing";
import FinancialWellness from "./components/Categories/FinancialWellness";
import GraphicsAndDesign from "./components/Categories/GraphicsAndDesign";
import HealthAndWellbeing from "./components/Categories/HealthAndWellbeing";
import Lifestyle from "./components/Categories/Lifestyle";
import MusicAndAudio from "./components/Categories/MusicAndAudio";
import Photography from "./components/Categories/Photography";
import ProgrammingAndTech from "./components/Categories/ProgrammingAndTech";
import RelationshipCounseling from "./components/Categories/RelationshipCounseling";
import VideoAndAnimation from "./components/Categories/VideoAndAnimation";
import WritingAndTranslation from "./components/Categories/WritingAndTranslation";
import CompletedCounsellor from "./components/Counsellor/CompletedSessions/completedSessions";
import RejectedCounsellor from "./components/Counsellor/RejectedSessions/rejectedSessions";
import Active from "./components/Home/Activation";
import Verify from "./components/Home/VerifyFirst";
import WhyA2z from "./components/WhyChooseUs/WhyChooseUs";
import CounseleeTransaction from "./components/Student/CounseleeTransaction/CounseleeTransaction";
//import CounsellorTransaction from "./components/Counsellor/CounsellorTransactions/CounsellorTransaction";
import Contactus from "./components/ContactUS/Contactus";
import CounseleeNotifications from "./components/Student/Notifications/AllNotifications";
//import CounsellorNotifications from "./components/Counsellor/Notifications/AllNotifications";
import UserContext from "./contexts/UserContext";
import PopularSessions from "./components/Student/PopularSkillsSessions/PopularSessions";
import SubCategories from "./components/Student/ProductSessions/ProductsSessions";
import Topskills from "./components/TopSkills/Topskills";
import Reset from "./components/Password/Reset";
import ForgotPassword from "./components/Password/Resetting";
import CounsellorPortfo from "./components/Counsellor/CounsellorProfile/CounsellorPortfo";
import Walletscreen from "./components/Counsellor/Wallet/Walletscreen";
import Paymentscreen from "./components/Counsellor/Paymentgateways/paymentscreen";
import Withdrawal from "./components/Withdrawal/Withdrawal";
import PreWithdrawal from "./components/Withdrawal/Pre-Withdrawal";

// import Rating from "../src/components/Rating/Rating";

function App() {
  const { userData } = useContext(UserContext);
  //console.log(userData);
  return (
    <>
      <Router className="container">
        <Suspense fallback={<div>Loading...</div>}>
          <Header />
          <Routes>
            <Route path="*" element={<PageNotfound />} />
            <Route
              exact
              path="/"
              element={
                userData?.flag === "STUDENT" ? (
                  <Studenthome />
                ) : userData?.flag === "COUNSELLOR" ? (
                  <CounsellorHome />
                ) : (
                  <Home />
                )
              }
            />
            <Route path="/register" element={<Register />} />
            <Route path="/login" element={<Login />} />
            <Route path="/students" element={<Studenthome />} />
            <Route path="/about-us" element={<Aboutus />} />
            <Route path="/cookies" element={<Cookies />} />
            <Route path="/session-pay" element={<WalletPayment />} />
            <Route path="/terms-and-conditions" element={<TAC />} />
            <Route path="/faqs" element={<Faqs />} />
            <Route path="/counsellor" element={<CounsellorHome />} />
            <Route path="/select" element={<SelectCounselling />} />
            <Route path="/session-details" element={<Sessiondetails />} />
            <Route path="/confirmation" element={<Conformation />} />
            <Route path="/event-create" element={<Eventcreate />} />
            <Route path="/counsellor-profile" element={<CounsellorProfile />} />
            <Route path="/edit-profile" element={<UpdateProfile />} />
            <Route path="/student-profile" element={<StudentProfile />} />
            <Route path="/student-update-profile" element={<EditProfile />} />
            <Route path="/search" element={<CounsellorResult />} />
            <Route path="/student-wallet" element={<StudentWallet />} />
            <Route path="/how-it-works" element={<HowItWorks />} />
            <Route path="/our-experts" element={<OurExperts />} />
            <Route path="/privacy-policy" element={<Privacypolicy />} />
            <Route path="/reviews" element={<Reviews />} />
            <Route path="/activated" element={<Active />} />
            <Route path="/verification" element={<Verify />} />
            <Route path="/why-a2z" element={<WhyA2z />} />
            <Route path="/withdrawal" element={<Withdrawal />} />
            <Route path="/pre-withdrawal" element={<PreWithdrawal />} />

            <Route
              path="/popular-skill-sessions"
              element={<PopularSessions />}
            />
            <Route path="/change-password/:id" element={<Reset />} />
            <Route
              path="/transactions"
              element={<CounseleeTransaction />}
            />
            <Route path="/subcategories" element={<SubCategories />} />
            {/* 
            <Route
              path="/counsellortransaction"
              element={<CounsellorTransaction />}
            />
            */}
            <Route path="/contact-us" element={<Contactus />} />

            <Route
              path="/upcoming-session-student"
              element={<UpcomingStudentSession />}
            />
            <Route
              path="/counsellor-upcoming-session"
              element={<CounsellorUpcomingSession />}
            />
            <Route path="/top-skills" element={<Topskills />} />
            <Route path="/completed-session" element={<CompletedSession />} />
            <Route path="/counsellor-completed-session" element={<CompletedCounsellor />} />
            <Route path="/rejected-session" element={<RejectedCounsellor />} />
            <Route
              path="/notifications"
              element={<CounseleeNotifications />}
            />
            {/* 
            <Route
              path="/counsellorNotification"
              element={<CounsellorNotifications />}
            />*/}
            <Route path="/forgot-password" element={<ForgotPassword />} />
            {/* {userData?.isProfile  ( */}
            <Route path="/portf" element={<CounsellorPortfo />} />
            <Route path="/wallet" element={<Walletscreen/>} />
            <Route path="/payment-screen" element={<Paymentscreen/>} />
            {/* )} */}
          </Routes>

          <Footer />
        </Suspense>
      </Router>
    </>
  );
}

export default React.memo(App);
